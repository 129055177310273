

export const environment = {
    production: false,
    apiUrl: 'https://eternallyme.dev.backend.urmbrme.com/api/v1/',
    socialUrl: 'https://eternallyme.dev.backend.urmbrme.com/',
    stripeSecretClient:'sk_test_51MmGbfBbEmiH546BUaBlbk3DP3CBZIQ9SUHTvtVrgDNbgHB6M2WifSGye2jgOyDTyP5AFJknfsXPvZrr1P2muu4Z00P3Brc9R1',
    stripePublicClient: 'pk_test_51MmGbfBbEmiH546B1v2MwyVzM1j4k5EgvEEIAOaQFXFH8cno5nBNdL86Ej7ndCPlvW6Hw32oQhz7F53aDMA1Qo8000M3meifuH',
    tinyMceKey: '4yv58x4c8fxjhdj3zffi8ulkv7c7z8tw1jhfh2o2hg45dc41'

};
